/* Built in imports */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

/* Third party imports */
import { connect } from 'react-redux';

import PropTypes from "prop-types";
/* Internal imports */
import DispositifActuelForm from '../../../../components/US/epargne_salariale/forms/DispositifActuelForm';
import { URLS_MAROQUINERIE } from '../../../../utils/constantes/ROUTES/MAROQUINERIE';
import { cloneValues, stringToBoolean } from '../../../../common/FunctionsUtils';
import {
  mettreAjourInteressement,
  mettreAjourParticipation,
  mettreAjourPlanExistant
} from '../../../../services/DossierService';
import {
  setInformationsUtilisateur, setInteressement, setParticipation,
  setPlanExistant,
  setRetourRoute
} from '../../../../modules/tunnel/redux/tunnelActions';

const MaroquinerieDispositifActuelPage = ({
                                            dossierUid,
                                            interessementActive,
                                            nombreSalaries,
                                            participationActive,
                                            setPlanExistant,
                                            setRetourRoute,
                                            setInteressement,
                                            setParticipation
                                          }) => {

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setRetourRoute(URLS_MAROQUINERIE.IDENTITE.substring(1));
  }, [])

  const submit = async (values) => {
    let valuesAenvoyer = Object.assign(cloneValues(values), {
      hasPlanExistant: stringToBoolean(values.hasPlanExistant),
      transfertPlan: stringToBoolean(values.transfertPlan),
      accordInteressementEnPlace: stringToBoolean(values.accordInteressementEnPlace),
      accordParticipationEnPlace: stringToBoolean(values.accordParticipationEnPlace),
      accordPPVEnPlace: stringToBoolean(values.accordPPVEnPlace)
    });

    try {
      const dossier = await mettreAjourPlanExistant(dossierUid, { valuesAenvoyer });
      setPlanExistant(dossier.planExistant);

      if (valuesAenvoyer.accordInteressementEnPlace && interessementActive) {
        await resetInteressementActive();
      }
      if (valuesAenvoyer.accordParticipationEnPlace && participationActive) {
        await resetParticipationActive();
      }
      setRedirect(true);
    } catch (error) {
      console.log(error);
    }
  }
  const resetInteressementActive = async () => {
    const interessementResetValues = {
      valuesAenvoye: {
        interessementActive: false
      }
    };
    try {
      const dossier = await mettreAjourInteressement(dossierUid, interessementResetValues);
      setInteressement(dossier.interessement);
    } catch (error) {
      if (error && error.message) {
        console.log(error);
      }
    }
  }

  const resetParticipationActive = async () => {
    const participationResetValues = {
      valuesAenvoye: {
        participationActive: false,
        optionParticipation: null
      }
    }
    try {
      const dossier = await mettreAjourParticipation(
        nombreSalaries,
        dossierUid,
        participationResetValues);

      setParticipation(dossier.participation);
    } catch (error) {
      if (error && error.message) {
        console.log(error);
      }
    }
  }

  if (redirect) {
    return <Redirect to={ URLS_MAROQUINERIE.INTERESSEMENT} />;
  }

  return (
    <div>
      <DispositifActuelForm onSubmit={submit} />
    </div>
  );
}

MaroquinerieDispositifActuelPage.propTypes = {
  tunnel: PropTypes.object.isRequired,
  setRetourRoute: PropTypes.func.isRequired,
  setPlanExistant: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dossierUid: state.tunnel.dossier.uid,
  nombreSalaries: state.tunnel.entreprise.complement.nombreSalaries,
  interessementActive: state.tunnel.dossier.interessement?.interessementActive,
  participationActive: state.tunnel.dossier.participation?.participationActive
});

const mapDispatchToProps = (dispatch) => ({
  setInformationsUtilisateur: (utilisateur) => dispatch(setInformationsUtilisateur(utilisateur)),
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setPlanExistant: (planExistant) => dispatch(setPlanExistant(planExistant)),
  setInteressement: (interessement) => dispatch(setInteressement(interessement)),
  setParticipation: (participation) => dispatch(setParticipation(participation)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaroquinerieDispositifActuelPage);

