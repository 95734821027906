/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Grid, CircularProgress, Snackbar, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

/* Internal imports */
import AdministrationFees from '../../../common/forms/tarification/AdministrationFees';
import AdviceFees from './AdviceFees';
import Button from '../../../../DS/inputs/Button';
import FeesDistribution from './FeesDistribution';
import TarificationBic from '../../../../DS/form/TarificationBic';
import TarificationIban from '../../../../DS/form/TarificationIban';
import Tarifications from '../../../common/forms/tarification/Tarifications';
import YesInput from '../../../../form/Input';
import { MOYENS_PAIEMENT } from '../../../../../utils/constantes/US/MOYENSPAIEMENT';
import { NormalizeIban } from '../../../../../common/Normalizers';
import { RESEAU_LFAC, STATUT_LIASSE } from '../../../../../common/Configuration';
import { Required } from '../../../../../common/Validators';
import { TYPE_VERSEMENT_TARIFICATION } from '../../../../../common/constants';
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';
import { URLS_EPARGNE } from '../../../../../utils/constantes/ROUTES/EPARGNE';
import { YesButton } from '../../../../form/Button';
import { envoiEmailBulletinAvantSignature } from "../../../../../services/DossierService";
import { getVersementDisponible } from './tarificationUtils';
import { isFraisConseilEnabled } from '../../../../../services/TenantService';

class EpargneTarificationForm extends Component {
  constructor(props) {
    super(props);
    const { pristine, invalid, submitting } = props;
    this.state = {
      showFraisConseil: false,
      showReviewInfos: false,
      isReseauLFAC: false,
      enableCibleFrais: false,
      loadingSendMail: false,
      notificationSendMail: null,
      submitDisabled: pristine || invalid || submitting
    };
    this.handleChangeFraisEntree = this.handleChangeFraisEntree.bind(this);
  }

  componentDidMount() {
    const { change } = this.props;
    const nextState = { ...this.state };
    const { apporteurAffaire, tarification, dispositifs, compteDePrelevement } = this.props.tunnel.dossier;

    if (apporteurAffaire) {
      if (apporteurAffaire.reseau === RESEAU_LFAC) {
        nextState.isReseauLFAC = true;
      }
    }
    if (compteDePrelevement) {
      change(MOYENS_PAIEMENT.IBAN, NormalizeIban(compteDePrelevement.iban));
      if (compteDePrelevement.bic) {
        change(MOYENS_PAIEMENT.BIC, compteDePrelevement.bic);
      }
    }
    if (tarification) {
      change('fraisDossier', tarification.fraisDossier);
      change('fraisEntree', tarification.fraisEntree + ' %');
      change('optionChargeFrais', tarification.optionChargeFrais.toString());
      if (tarification.fraisEntree > 0) {
        nextState.enableCibleFrais = true;
        change('ciblePrelevements', tarification.ciblePrelevements);
      }
      if (tarification.applicationFraisConseil) {
        nextState.showFraisConseil = true;
        change('applicationFraisConseil', tarification.applicationFraisConseil);
        change('fraisConseil', tarification.fraisConseil);
      }
      if (tarification.fraisRepartition && tarification.fraisEntree > 0) {
        change('repartitionEntreprise', tarification.fraisRepartition.repartitionEntreprise);
        change('repartitionBeneficiaire', tarification.fraisRepartition.repartitionBeneficiaire);
      }
      if (tarification.fraisTypeVersement && tarification.fraisEntree > 0) {
        const versementsDisponibles = getVersementDisponible(dispositifs.dispositifPei, dispositifs.dispositifPercoi);

        change('participation', versementsDisponibles.includes(TYPE_VERSEMENT_TARIFICATION.PARTICIPATION) ? tarification.fraisTypeVersement.participation : null);
        change('interessement', versementsDisponibles.includes(TYPE_VERSEMENT_TARIFICATION.INTERESSEMENT) ? tarification.fraisTypeVersement.interessement : null);
        change('versementsVolontaires', tarification.fraisTypeVersement.versementsVolontaires);
        change(
          'versementsComplementaires', versementsDisponibles.includes(TYPE_VERSEMENT_TARIFICATION.ABONDEMENT) ? tarification.fraisTypeVersement.versementsComplementaires : null
        );
        change('versementCET', versementsDisponibles.includes(TYPE_VERSEMENT_TARIFICATION.JOURS_CET) ? tarification.fraisTypeVersement.versementCET : null);
        change('versementPPV', versementsDisponibles.includes(TYPE_VERSEMENT_TARIFICATION.PPV) ? tarification.fraisTypeVersement.versementPPV : null);
      }
    } else {
      change('fraisDossier', 500);
      change('fraisEntree', '5 %');
      nextState.enableCibleFrais = true;
    }
    this.setState(nextState);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { pristine, invalid, submitting } = this.props;
    if (prevProps.pristine !== pristine || prevProps.invalid !== invalid || prevProps.submitting !== submitting) {
      this.setState({
        submitDisabled: pristine || invalid || submitting
      });
    }
  }

  hasLiasseSignee = () => !!(this.props.tunnel.liasse && this.props.tunnel.liasse.statut === STATUT_LIASSE.SIGNEE);

  toggleFraisConseil = () => this.setState({ showFraisConseil: !this.state.showFraisConseil });

  getValueFromPourcentMask = (value) => parseFloat(value.substring(0, value.length - 1).replace(',', '.'));

  handleChangeFraisEntree = (event) => {
    const value = this.getValueFromPourcentMask(event.target.value);
    if (value > 5) {
      this.props.change('fraisEntree', 5);
    }
    this.setState({ enableCibleFrais: value > 0 ? true : false });
  };

  handleSendContractToCustomer = async () => {
    this.setState({ loadingSendMail: true });
    try {
      await envoiEmailBulletinAvantSignature(this.props.tunnel.dossier.uid);
      this.setState({ notificationSendMail: 'success' });
    } catch (error) {
      this.setState({ notificationSendMail: 'error' })
    } finally {
      this.setState({ loadingSendMail: false });
    }
  };
  handleCloseSnackbar = () => {
    this.setState({ notificationSendMail: null });
  };

  checkDroitEntree = (value) => {
    const mininumParReseau =
      this.props.tunnel.dossier.apporteurAffaire && this.props.tunnel.dossier.apporteurAffaire.reseau === RESEAU_LFAC ? 0.5 : 0;
    const formattedValue = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;
    return formattedValue <= 5 && formattedValue >= mininumParReseau
      ? undefined
      : `La valeur des droits d'entrée doit être comprise entre ${mininumParReseau} et 5`;
  };

  render() {
    const { handleSubmit, customErrorIban, optionSelected, tunnel } = this.props;
    const { loadingSendMail, notificationSendMail } = this.state;
    const {
      dossier: {
        dispositifs: { dispositifPei, dispositifPercoi }
      }
    } = tunnel;

    return (
      <form onSubmit={handleSubmit} className="formTunnel formTarification">
        <label className="titreFormulaireGes">
          <TransHtmlText i18nKey="ges-tarification.titre.principal" />
          <Trans i18nKey="ges-tarification.HT" />
        </label>
        <p>
          <label className="titreFormulaire titreTarificationGes">
            <Trans
              i18nKey={
                dispositifPei.ouverture && dispositifPercoi.ouverture
                  ? 'ges-tarification.titre.1.multi'
                  : dispositifPei.ouverture
                  ? 'ges-tarification.titre.1.pei'
                  : 'ges-tarification.titre.1.percol'
              }
            />
          </label>
        </p>

        <Tarifications
          dispositifPercoi={this.props.tunnel.dossier.dispositifs.dispositifPercoi}
          dispositifPei={this.props.tunnel.dossier.dispositifs.dispositifPei}
        />

        <AdministrationFees optionSelected={optionSelected} />

        {isFraisConseilEnabled() && !this.state.isReseauLFAC && (
          <AdviceFees selectAdviceFee={this.toggleFraisConseil} showFraisConseil={this.state.fraisConseil} />
        )}
        {/*Fin des frais de conseil*/}

        <div>
          <label className="titreFormulaire titreTarificationGes">
            <Trans i18nKey="ges-tarification.titre.4" />
          </label>
          <div className="ges-tarification-inline-display">
            <p className={'ges-libelle-tarification'}>
              <Trans i18nKey="ges-tarification.fraisEntree.question.partFrais" />
            </p>
            <div className="tarification-input fraisEntree-input">
              <Field
                pourcentDecimalField={true}
                component={YesInput}
                inputMode="number"
                name="fraisEntree"
                labeltext=""
                validate={[Required, this.checkDroitEntree]}
                onChange={this.handleChangeFraisEntree}
              />
            </div>
          </div>
          {this.state.enableCibleFrais && (
            <FeesDistribution optionSelected={optionSelected} selectables={getVersementDisponible(dispositifPei, dispositifPercoi)} />
          )}
        </div>

        {/*Fin de l'affichage des logos*/}

        <div>
          <label className="titreFormulaire" htmlFor="ibanBicSubsection">
            <Trans i18nKey="ges-tarification.titre.5" />
          </label>
          <Grid container spacing={2} sx={{ mt: '-30px' }}>
            <Grid item xs={6}>
              <TarificationIban customError={customErrorIban} />
            </Grid>
            <Grid item xs={3}>
              <TarificationBic />
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={2} className="buttonCreationContainer" >
          <Grid item xs={12}>
            <Button
                variant="contained"
                onClick={this.handleSendContractToCustomer}
                className="yes-button"
                disabled={loadingSendMail}
            >
              {loadingSendMail ? <CircularProgress color="inherit" className="ges-circular-progress" size={50} /> : `Envoyer une copie du bulletin au client`}
            </Button>
          </Grid>
          <Snackbar
              open={notificationSendMail !== null}
              autoHideDuration={6000}
              onClose={this.handleCloseSnackbar}
          >
            {notificationSendMail === 'success' ? (
                <Alert onClose={this.handleCloseSnackbar} severity="success">
                  Bulletin envoyé avec succès !
                </Alert>
            ) : notificationSendMail === 'error' ? (
                <Alert onClose={this.handleCloseSnackbar} severity="error">
                  Échec de l'envoi du Bulletin. Veuillez réessayer.
                </Alert>
            ) : null}
          </Snackbar>
          <Grid item xs={6}>
            <YesButton text="Signer le contrat" disabled={this.state.submitDisabled} />
          </Grid>
        </Grid>
        <div className="buttonCreationContainer">
          {this.hasLiasseSignee() ? (
            <p>
              J’ai déjà signé mon contrat et je n’en ai pas modifié les paramètres :{' '}
              <Link to={URLS_EPARGNE.FINALISATION}>j'envoie mes pièces justificatives.</Link>
            </p>
          ) : null}
        </div>
      </form>
    );
  }
}

EpargneTarificationForm.propTypes = {
  change: PropTypes.func.isRequired,
  tunnel: PropTypes.object
};

// REDUX + REDUX FORM
const selectTarificationForm = formValueSelector('tarificationForm');
const mapStateToProps = (state) => ({
  fraisConseil: selectTarificationForm(state, 'fraisConseil'),
  fraisDossier: selectTarificationForm(state, 'fraisDossier'),
  droitEntree: selectTarificationForm(state, 'fraisEntree'),
  optionSelected: selectTarificationForm(state, 'optionChargeFrais'),
  tunnel: state.tunnel
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'tarificationForm' })(EpargneTarificationForm));
