import { URL_SUBSCRIPTION_TYPE } from './ROUTES';

export const URLS_EPARGNE = {
  SIRET: URL_SUBSCRIPTION_TYPE.EPARGNE + '/siret',
  ENTREPRISE_INFORMATION: URL_SUBSCRIPTION_TYPE.EPARGNE + '/informations',
  ENTREPRISE_COMPLEMENT: URL_SUBSCRIPTION_TYPE.EPARGNE + '/salaries',
  IDENTITE: URL_SUBSCRIPTION_TYPE.EPARGNE + '/representant',
  DISPOSITIF_ACTUEL: URL_SUBSCRIPTION_TYPE.EPARGNE + '/dispositif-actuel',
  INTERESSEMENT: URL_SUBSCRIPTION_TYPE.EPARGNE + '/interessement',
  PARTICIPATION: URL_SUBSCRIPTION_TYPE.EPARGNE + '/participation',
  DISPOSITIF_ABONDEMENT: URL_SUBSCRIPTION_TYPE.EPARGNE + '/abondement',
  KYC: URL_SUBSCRIPTION_TYPE.EPARGNE + '/kyc',
  TARIFICATION: URL_SUBSCRIPTION_TYPE.EPARGNE + '/tarification',
  FINALISATION: URL_SUBSCRIPTION_TYPE.EPARGNE + '/finalisation'
};

export const ETAPES_DOSSIER_EPARGNE = {
  SIRET: 0,
  ENTREPRISE_INFORMATION: 1,
  ENTREPRISE_COMPLEMENT: 2,
  IDENTITE: 3,
  DISPOSITIF_ACTUEL: 4,
  INTERESSEMENT: 5,
  PARTICIPATION: 6,
  DISPOSITIF_ABONDEMENT: 7,
  KYC: 8,
  TARIFICATION: 9,
  EN_ATTENTE_DE_SIGNATURE: 9,
  INFORMATIONS: 10,
  TRANSMIS: 11
};

export const SECTIONS_EPARGNE = {
  ENTREPRISE: {
    URL: URLS_EPARGNE.ENTREPRISE_INFORMATION,
    INDEX: 0,
    ETAPE: ETAPES_DOSSIER_EPARGNE.ENTREPRISE_INFORMATION
  },
  BESOIN: {
    URL: URLS_EPARGNE.DISPOSITIF_ACTUEL,
    INDEX: 1,
    ETAPE: ETAPES_DOSSIER_EPARGNE.DISPOSITIF_ACTUEL
  },
  ADHESION: {
    URL: URLS_EPARGNE.TARIFICATION,
    INDEX: 2,
    ETAPE: ETAPES_DOSSIER_EPARGNE.TARIFICATION
  }
};
