/**
 * Ce module regroupe toutes les fonctions unitaires de calcul nécessaires à la mise à jour des champs du simulateur
 * Elles sont utilisées par les reducers pour mettre à jour le state lors des interactions utilisateurs.
 */

import {
  TAUX_MAX_ABONDEMENT_PEE,
  TAUX_MAX_ABONDEMENT_PERCO,
  TAUX_MAX_INTERESSEMENT,
  TAUX_MAX_PARTICIPATION,
  PLAFOND_ABONDEMENT_UNILATERAL,
  PLAFOND_ABONDEMENT_UNILATERAL_PLAN_EN_PLACE,
  FORFAIT_SOCIAL_PEE_MOINS_50,
  FORFAIT_SOCIAL_PEE_PLUS_50,
  FORFAIT_SOCIAL_PERCO_MOINS_50,
  FORFAIT_SOCIAL_PERCO_PLUS_50,
  FORFAIT_SOCIAL_INTERESSEMENT_MOINS_250,
  FORFAIT_SOCIAL_INTERESSEMENT_250_PLUS,
  FORFAIT_SOCIAL_PARTICIPATION_MOINS_50,
  FORFAIT_SOCIAL_PARTICIPATION_50_PLUS,
  CSG_CRDS,
  INTERESSEMENT_POURCENTAGE_MAX_MASSE_SALARIALE
} from './configuration';
import { getCurrentPassFromStore } from '../../utils/passUtils';

// Fonctions de calcul du plafond des différentes poches d'épargne salariale
export const plafondAbondementPEE = () => Math.trunc(getCurrentPassFromStore() * TAUX_MAX_ABONDEMENT_PEE);
export const plafondAbondementPERCO = (value = 0) => Math.trunc(getCurrentPassFromStore() * TAUX_MAX_ABONDEMENT_PERCO) - value;
export const plafondInteressement = () => Math.trunc(getCurrentPassFromStore() * TAUX_MAX_INTERESSEMENT);
export const plafondParticipation = () => Math.trunc(getCurrentPassFromStore() * TAUX_MAX_PARTICIPATION);

export const accordsInteressementOuParticipationEnPlace = (dossier) => ((dossier.planExistant?.accordInteressementEnPlace || dossier.planExistant?.accordParticipationEnPlace) ||
  dossier.interessement?.interessementActive || dossier.participation?.participationActive);
export const plafondAbondementUnilateral = (dossier) => {
  if (accordsInteressementOuParticipationEnPlace(dossier)) {
    return PLAFOND_ABONDEMENT_UNILATERAL_PLAN_EN_PLACE;
  }
  return PLAFOND_ABONDEMENT_UNILATERAL
};

// Calcul du forfait social
export const forfaitSocialAbondementPEE = (abondementPEE, effectif) => {
  if (effectif >= 50) {
    return Math.floor(abondementPEE * FORFAIT_SOCIAL_PEE_PLUS_50);
  } else {
    return Math.floor(abondementPEE * FORFAIT_SOCIAL_PEE_MOINS_50);
  }
};
export const forfaitSocialAbondementPERCO = (abondementPERCO, effectif) => {
  if (effectif >= 50) {
    return Math.floor(abondementPERCO * FORFAIT_SOCIAL_PERCO_PLUS_50);
  } else {
    return Math.floor(abondementPERCO * FORFAIT_SOCIAL_PERCO_MOINS_50);
  }
};
export const forfaitSocialInteressement = (interessement, effectif) => {
  if (effectif >= 250) {
    return Math.floor(interessement * FORFAIT_SOCIAL_INTERESSEMENT_250_PLUS);
  } else {
    return Math.floor(interessement * FORFAIT_SOCIAL_INTERESSEMENT_MOINS_250);
  }
};
export const forfaitSocialParticipation = (participation, effectif) => {
  if (effectif >= 50) {
    return Math.floor(participation * FORFAIT_SOCIAL_PARTICIPATION_50_PLUS);
  } else {
    return Math.floor(participation * FORFAIT_SOCIAL_PARTICIPATION_MOINS_50);
  }
};
export const forfaitSocial = ({ abondementPEE, abondementPERCO, interessement, participation }, effectif) => ({
  abondementPEE: forfaitSocialAbondementPEE(abondementPEE, effectif),
  abondementPERCO: forfaitSocialAbondementPERCO(abondementPERCO, effectif),
  interessement: forfaitSocialInteressement(interessement, effectif),
  participation: forfaitSocialParticipation(participation, effectif),
  total: Math.floor(
    forfaitSocialAbondementPEE(abondementPEE, effectif) +
      forfaitSocialAbondementPERCO(abondementPERCO, effectif) +
      forfaitSocialInteressement(interessement, effectif) +
      forfaitSocialParticipation(participation, effectif)
  )
});

// Fonctions de calcul des budgets max
export const budgetMaxPossible = (masseSalariale) => Math.floor(0.05 * masseSalariale);
export const budgetParPersonne = (masseSalariale, effectif) => Math.floor(budgetMaxPossible(masseSalariale) / effectif);
export const budget = (masseSalariale, effectif) => ({
  maxPossible: budgetMaxPossible(masseSalariale),
  parPersonne: budgetParPersonne(masseSalariale, effectif)
});

export const coutEntrepriseEpargneSalariale = (dispositif, effectif) => {
  let forfaitSocialResult = forfaitSocial(dispositif, effectif);
  return {
    forfaitSocial: forfaitSocialResult,
    abondementPEE: dispositif.abondementPEE + forfaitSocialResult.abondementPEE,
    abondementPERCO: dispositif.abondementPERCO + forfaitSocialResult.abondementPERCO,
    interessement: dispositif.interessement + forfaitSocialResult.interessement,
    participation: dispositif.participation + forfaitSocialResult.participation,
    coutTotalEntreprise: Math.floor(
      forfaitSocialResult.total +
        dispositif.abondementPEE +
        dispositif.abondementPERCO +
        dispositif.interessement +
        dispositif.participation
    )
  };
};

export const sommePercueSalarieEpargneSalariale = (enveloppeIndividuelle) => ({
  csgCrds: Math.floor(enveloppeIndividuelle * CSG_CRDS),
  sommePercueSalarie: Math.floor(enveloppeIndividuelle * (1 - CSG_CRDS))
});

// ---- Fonctions d'affectation aux poches de l'épargne salariale ----
export const affecterAbondementPEE = ({ resteAffecter, ...autresPoches }) => {
  const abondementPEE = Math.min(resteAffecter, plafondAbondementPEE());
  return {
    resteAffecter: Math.floor(resteAffecter - abondementPEE),
    abondementPEE,
    ...autresPoches
  };
};
export const affecterAbondementPERCO = ({ resteAffecter, ...autresPoches }) => {
  const abondementPERCO = Math.min(resteAffecter, plafondAbondementPERCO());
  return {
    resteAffecter: Math.floor(resteAffecter - abondementPERCO),
    abondementPERCO,
    ...autresPoches
  };
};
export const affecterInteressement = ({ resteAffecter, ...autresPoches }, masseSalariale, effectif) => {
  const salaireIndividuel = masseSalariale / effectif;

  const interessementAjour = appliquer20pourcentMasseSalarialeEnCasDeDepassement(
    Math.min(resteAffecter, plafondInteressement()),
    salaireIndividuel
  );

  return {
    resteAffecter: Math.floor(resteAffecter - interessementAjour.interessement),
    interessement: interessementAjour.interessement,
    depassement20pourcentMasseSalariale: interessementAjour.depassement20pourcentMasseSalariale,
    ...autresPoches
  };
};

export const appliquer20pourcentMasseSalarialeEnCasDeDepassement = (interessement, salaireIndividuel) => {
  let depassement20pourcentMasseSalariale = false;

  if (interessement > salaireIndividuel * INTERESSEMENT_POURCENTAGE_MAX_MASSE_SALARIALE) {
    interessement = Math.floor(salaireIndividuel * INTERESSEMENT_POURCENTAGE_MAX_MASSE_SALARIALE);
    depassement20pourcentMasseSalariale = true;
  }

  return { interessement, depassement20pourcentMasseSalariale };
};

export const affecterParticipation = ({ resteAffecter, ...autresPoches }, masseSalariale, effectif, montantParticipation) => {
  let participation = 0;

  if (montantParticipation || montantParticipation === 0) {
    participation = Math.min(Math.floor(montantParticipation / effectif), plafondParticipation());

    if (participation > resteAffecter) {
      participation = resteAffecter;
    }
  } else {
    participation = Math.min(resteAffecter, plafondParticipation());
  }

  return {
    resteAffecter: resteAffecter - participation,
    participation,
    ...autresPoches
  };
};

// Définit l'ordre d'enchainement des fonctions d'affectation en fonction de l'objectif pour les entreprises de moins de 50 personnes
const FIDELISER_AFFECTATION_MOINS_50 = [
  affecterAbondementPEE,
  affecterAbondementPERCO,
  affecterInteressement,
  affecterParticipation
];
const MOTIVER_AFFECTATION_MOINS_50 = [affecterInteressement, affecterAbondementPEE, affecterAbondementPERCO, affecterParticipation];
const CAPITALISER_AFFECTATION_MOINS_50 = [
  affecterAbondementPEE,
  affecterAbondementPERCO,
  affecterInteressement,
  affecterParticipation
];

// Définit l'ordre d'enchainement des fonctions d'affectation en fonction de l'objectif pour les entreprises de 5O salariés et plus
const FIDELISER_AFFECTATION_50_PLUS = [
  affecterParticipation,
  affecterAbondementPEE,
  affecterAbondementPERCO,
  affecterInteressement
];
const MOTIVER_AFFECTATION_50_PLUS = [affecterParticipation, affecterInteressement, affecterAbondementPEE, affecterAbondementPERCO];

// CE CAS N'ARRIVERA JAMAIS, CAR SI EFFECTIF > 10, CET OBJECTIF N'EST PAS ENVISAGEABLE
const CAPITALISER_AFFECTATION_50_PLUS = [
  affecterAbondementPEE,
  affecterAbondementPERCO,
  affecterInteressement,
  affecterParticipation
];

/**
 * Construit la fonction à appliquer sur la masse salariale et l'effectif pour déterminer la recommandation en fonction de l'objectif.
 *
 * L'objectif a juste une influence sur l'ordre dans lequel sont appliquées les fonctions d'affectation.
 *
 * Array.reduce est utilisé pour enchainer l'appel aux fonctions d'affectation
 */
const recommandationFunction = (moins50affectationFcts, plus50affectationFcts) => (
  masseSalariale,
  effectif,
  montantParticipation
) => {
  let result;

  if (effectif >= 50) {
    // Effectif à partir de 50 salariés, utilisation de l'ordre plus50affectationFcts
    result = plus50affectationFcts.reduce((acc, affectFct) => affectFct(acc, masseSalariale, effectif, montantParticipation), {
      resteAffecter: budgetParPersonne(masseSalariale, effectif)
    });
  } else {
    // Effectif moins de 50 salariés, utilisation de l'ordre moins50affectationFcts
    result = moins50affectationFcts.reduce((acc, affectFct) => affectFct(acc, masseSalariale, effectif), {
      resteAffecter: budgetParPersonne(masseSalariale, effectif)
    });
  }

  return {
    ...result,
    enveloppeIndividuelle: result.abondementPEE + result.abondementPERCO + result.interessement + result.participation
  };
};

export const recommandationFideliser = recommandationFunction(FIDELISER_AFFECTATION_MOINS_50, FIDELISER_AFFECTATION_50_PLUS);
export const recommandationMotiver = recommandationFunction(MOTIVER_AFFECTATION_MOINS_50, MOTIVER_AFFECTATION_50_PLUS);
export const recommandationCapitaliser = recommandationFunction(CAPITALISER_AFFECTATION_MOINS_50, CAPITALISER_AFFECTATION_50_PLUS);

export const coutEntreprisePrimeClassique = (primeAttribuee, tauxChargesPatronales) => ({
  chargesPatronales: Math.floor(primeAttribuee * tauxChargesPatronales),
  coutTotalEntreprise: Math.floor(primeAttribuee * (1 + tauxChargesPatronales))
});

export const sommePercueSalariePrimeClassique = (primeAttribuee, tauxChargesSalariales, tauxMarginalDImposition) => {
  const csgCrds = Math.floor(primeAttribuee * CSG_CRDS);
  const chargesSocialesSalarie = Math.floor(primeAttribuee * tauxChargesSalariales);
  const impotSurRevenu = Math.floor((primeAttribuee - csgCrds - chargesSocialesSalarie) * tauxMarginalDImposition);
  const sommePercueSalarie = Math.floor(primeAttribuee - csgCrds - chargesSocialesSalarie - impotSurRevenu);
  const chargesTotalesSalarie = csgCrds + chargesSocialesSalarie + impotSurRevenu;
  return {
    csgCrds,
    chargesSocialesSalarie,
    impotSurRevenu,
    sommePercueSalarie,
    chargesTotalesSalarie
  };
};
