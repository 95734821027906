// Classe de configuration de l'application

/* CONFIGURATION GLOBALE, QUELQUE SOIT L'ENVIRONNEMENT */

// prefixe du back
export const PREFIX_BACK = process.env.REACT_APP_CITRA_URL + '/v1/';

export const PREFIX_TUNNEL_DIRIGEANT = '/tunneldirigeant';

// fréquence de rafraichissement du token JWT en millisecondes : 10 minutes
export const FREQUENCE_RAFRAICHISSEMENT_TOKEN = 60000;

export const WAIT_REFRAICHISSEMENT_TOKEN_EN_COURS = 1000;

// Nom des routes de l'application
export const ROUTE_HOME = '/';
export const ROUTE_CONNEXION = '/connexion';
export const ROUTE_ACCUEIL_GALAXY = '/accueil';
export const ROUTE_DOSSIERS_GALAXY = '/souscrire/suivi-dossiers';
export const ROUTE_SIMULATEUR = '/simulateur';
export const ROUTE_CREATION_COMPTE = '/creationCompte';
export const ROUTE_PPE = PREFIX_TUNNEL_DIRIGEANT + '/ppe';
export const ROUTE_KYC = PREFIX_TUNNEL_DIRIGEANT + '/kyc';
export const ROUTE_CHANGEMENT_MDP = '/changementMotDePasseOublie';
export const ROUTE_SIRET = PREFIX_TUNNEL_DIRIGEANT + '/votresiret';
export const ROUTE_ACCES_CONTRIBUTEUR = '/connexion/accesContributeur';
export const ROUTE_CONTINUER_SUR_ORDINATEUR = '/continuerSurOrdinateur';
export const ROUTE_IDENTITE = PREFIX_TUNNEL_DIRIGEANT + '/votreidentite';
export const ROUTE_DEMANDE_CHANGEMENT_MDP = '/demandeChangementMotDePasse';
export const ROUTE_TARIFICATION = PREFIX_TUNNEL_DIRIGEANT + '/tarification';
export const ROUTE_INFORMATIONS = PREFIX_TUNNEL_DIRIGEANT + '/informations';
export const ROUTE_ENTREPRISE = PREFIX_TUNNEL_DIRIGEANT + '/votreentreprise';
export const ROUTE_INTERESSEMENT = PREFIX_TUNNEL_DIRIGEANT + '/interessement';
export const ROUTE_PARTICIPATION = PREFIX_TUNNEL_DIRIGEANT + '/participation';
export const ROUTE_MOYENS_PAIEMENT = PREFIX_TUNNEL_DIRIGEANT + '/moyensdepaiement';
export const ROUTE_EN_ATTENTE_DE_SIGNATURE = PREFIX_TUNNEL_DIRIGEANT + '/recapitulatif';
export const ROUTE_DISPOSITIF_ACTUEL = PREFIX_TUNNEL_DIRIGEANT + '/votredispositifactuel';
export const ROUTE_GARANTIES_PREVOYANCE = PREFIX_TUNNEL_DIRIGEANT + '/garantiesprevoyance';
export const ROUTE_FORMULE_PARTICIPATION = PREFIX_TUNNEL_DIRIGEANT + '/formuleParticipation';
export const ROUTE_DISPOSITIF_ABONDEMENT = PREFIX_TUNNEL_DIRIGEANT + '/dispositifAbondement';
export const ROUTE_ENTREPRISE_COMPLEMENT = PREFIX_TUNNEL_DIRIGEANT + '/votreentreprisecomplement';
export const ROUTE_VERSION_VIEWER = '/version'

// ORDRE DES ETAPES :
export const ORDRE_ETAPE_TUNNEL = {
  [ROUTE_SIRET]: 0,
  [ROUTE_ENTREPRISE]: 0,
  [ROUTE_ENTREPRISE_COMPLEMENT]: 1,
  [ROUTE_IDENTITE]: 2,
  [ROUTE_PPE]: 3,
  [ROUTE_DISPOSITIF_ACTUEL]: 3,
  [ROUTE_INTERESSEMENT]: 4,
  [ROUTE_PARTICIPATION]: 5,
  [ROUTE_DISPOSITIF_ABONDEMENT]: 6,
  [ROUTE_GARANTIES_PREVOYANCE]: 7,
  '/tunneldirigeant/abondementUnilateral': 7,
  '/tunneldirigeant/fraiscontrat': 8,
  [ROUTE_FORMULE_PARTICIPATION]: 8,
  [ROUTE_KYC]: 9,
  [ROUTE_MOYENS_PAIEMENT]: 10,
  [ROUTE_TARIFICATION]: 11,
  [ROUTE_EN_ATTENTE_DE_SIGNATURE]: 12,
  [ROUTE_INFORMATIONS]: 13
};

// ETAPE => ROUTE
export const ETAPE_TUNNEL = {
  KYC: ROUTE_KYC,
  IDENTITE: ROUTE_IDENTITE,
  TRANSMIS: ROUTE_INFORMATIONS,
  TARIFICATION: ROUTE_TARIFICATION,
  INFORMATIONS: ROUTE_INFORMATIONS,
  INTERESSEMENT: ROUTE_INTERESSEMENT,
  PARTICIPATION: ROUTE_PARTICIPATION,
  PPE: ROUTE_PPE,
  MOYENS_PAIEMENT: ROUTE_MOYENS_PAIEMENT,
  ENTREPRISE_INFORMATION: ROUTE_ENTREPRISE,
  DISPOSITIF_ACTUEL: ROUTE_DISPOSITIF_ACTUEL,
  GARANTIES_PREVOYANCE: ROUTE_GARANTIES_PREVOYANCE,
  ENTREPRISE_COMPLEMENT: ROUTE_ENTREPRISE_COMPLEMENT,
  DISPOSITIF_ABONDEMENT: ROUTE_DISPOSITIF_ABONDEMENT,
  EN_ATTENTE_DE_SIGNATURE: ROUTE_EN_ATTENTE_DE_SIGNATURE,
  PARTICIPATION_CHOIX_FORMULE: ROUTE_FORMULE_PARTICIPATION
};

export const DIRIGEANT = 'DIRIGEANT';
export const FLAG_REDIRECT = '?c';

export const TELEPHONE = '01 75 85 02 74';

export const CONTACT_EMAIL = 'epargne.salariale@generali.com';
export const CONTACT_EMAIL_ASSURANCE = 'epargne.retraite@generali.fr';

export const SEUIL_MOBILE = 480;

export const FORMULE_LEGALE = 'FORMULE_LEGALE';

export const RESEAU_LFAC = 'LFAC';

export const RESEAU_AGENT_GENERAUX = 'Agents généraux';

export const FORMULE_DEROGATOIRE = 'FORMULE_DEROGATOIRE';

export const STATUT_LIASSE = {
  ANNULEE: 'ANNULEE',
  SIGNEE: 'SIGNEE',
  ENVOYEE: 'ENVOYEE'
};

export const TYPE_DOCUMENT = {
  RIB: 'RIB',
  ANNEXE_KYC_PERSON: 'ANNEXE_KYC_PERSON',
  ANNEXE_KYC_ENTITY: 'ANNEXE_KYC_ENTITY',
  PLAN_EXISTANT: 'PLAN_EXISTANT',
  ACCORD_INTERESSEMENT: 'ACCORD_INTERESSEMENT',
  ACCORD_PARTICIPATION: 'ACCORD_PARTICIPATION',
  ACCORD_PPV: 'ACCORD_PPV',
  CAS_PARTICULIERS: 'CAS_PARTICULIERS',
  DELEGATION_POUVOIR: 'DELEGATION_POUVOIR',
  STATUTS_ENTREPRISE: 'STATUTS_ENTREPRISE',
  LISTE_BENEFICIAIRES: 'LISTE_BENEFICIAIRES',
  JUSTIFICATIF_IDENTITE: 'JUSTIFICATIF_IDENTITE',
  JUSTIFICATIF_PASSEPORT: 'JUSTIFICATIF_PASSEPORT',
  CONSULTATION_ENTREPRISE: 'CONSULTATION_ENTREPRISE',
  JUSTIFICATIF_IDENTITE_RECTO: 'JUSTIFICATIF_IDENTITE_RECTO',
  JUSTIFICATIF_IDENTITE_VERSO: 'JUSTIFICATIF_IDENTITE_VERSO',
  JUSTIFICATIF_IDENTITE_ENTREPRISE: 'JUSTIFICATIF_IDENTITE_ENTREPRISE',
  LIASSE_FISCALE: 'LIASSE_FISCALE',

  //Associations déclarées & publiques
  STATUTS_ASSOC: 'STATUTS_ASSOC',
  RECEP_DECLA_PREF: 'RECEP_DECLA_PREF',
  RECEP_INSERTION: 'RECEP_INSERTION',

  //Associations publiques
  DECRET_RECO: 'DECRET_RECO',

  //Profession libérale
  CARTE_PROFESSIONELLE: 'CARTE_PROFESSIONELLE',
  INSCRIPTION_REGISTRE_ORDRE: 'INSCRIPTION_REGISTRE_ORDRE'
};

export const DOCUMENTS = [
  {
    type: TYPE_DOCUMENT.STATUTS_ENTREPRISE,
    libelle: 'Statuts de l’entreprise',
    entite: 'entreprises'
  },
  {
    type: TYPE_DOCUMENT.JUSTIFICATIF_IDENTITE_ENTREPRISE,
    libelle: "Extrait K-BIS de l'entreprise de moins de 3 mois ou justificatif d'inscription à l'ordre des métiers",
    entite: 'entreprises'
  },
  {
    type: TYPE_DOCUMENT.CAS_PARTICULIERS,
    libelle: 'Cas particuliers :',
    entite: 'entreprises'
  }
];

export const DOCUMENTS_ANNEXE_ASSURANCE = [
  {
    type: TYPE_DOCUMENT.LIASSE_FISCALE,
    libelle: 'Liasse fiscale complète (année N-1) ou Formulaire DBE -S1 relatif aux bénéficiaires effectifs (Cerfa n° 16062*02)',
    entite: 'fiches'
  },
  {
    type: TYPE_DOCUMENT.ANNEXE_KYC_PERSON,
    libelle: 'Pièce(s) d’identité (*) du (des) bénéficiaire(s) effectif(s)',
    entite: 'dossiers'
  }
];

export const DOUCMENTS_IDENTITE_DIRIGEANT = [
  {
    type: 'JUSTIFICATIF_PASSEPORT',
    libelle: 'Passeport du dirigeant',
    entite: 'utilisateurs'
  },
  {
    type: 'JUSTIFICATIF_IDENTITE_RECTO',
    libelle: 'Justificatif d’identité du dirigeant (recto)',
    entite: 'utilisateurs'
  },
  {
    type: 'JUSTIFICATIF_IDENTITE_VERSO',
    libelle: 'Justificatif d’identité du dirigeant (verso)',
    entite: 'utilisateurs'
  },
  {
    type: 'DELEGATION_POUVOIR',
    libelle: 'Délégation de pouvoir',
    entite: 'entreprises'
  }
];

// Equivaut à 20mo
export const FILE_SIZE_LIMIT = 20971520;

export const FILE_SIZE_ERROR = 'Le fichier envoyé a une taille supérieure à 20 Mo.';
export const FILE_NAME_ERROR = 'Le fichier contient des caractères spéciaux.';
