/* Built in imports */
import React from 'react';

/* Third party imports */
import { Trans } from 'react-i18next';

/* Internal imports */
import TooltipInfo, { MAXWIDTH } from '../utils/US/components/TooltipInfo';
import Typography from '../components/DS/data-display/Typography';
import config from '../common/StaticSiteConfig';
import { TAUX_MAX_ABONDEMENT_PEE, TAUX_MAX_ABONDEMENT_PERCO } from '../modules/simulateur/configuration';
import { TransHtmlText } from '../components/yes/utils/TransHtmlText';
import { nombreAvecEspaces, frenchDecimalNumber } from '../common/FunctionsUtils';
import {
  ROUTE_FORMULE_PARTICIPATION
} from '../common/Configuration';
import {
  isSiretInfoPartie2Enabled,
  isInfoCardEntreprisePart1Enabled,
  isParticipationVolontaireEnabled,
  isDirigeantInfoEnabled
} from '../services/TenantService';
import { COLOR } from '../utils/constantes/DS/TYPOGRAPHY';
import { URLS_ASSURANCE } from '../utils/constantes/ROUTES/ASSURANCE';
import { URLS_EPARGNE } from '../utils/constantes/ROUTES/EPARGNE';
import { getListeBeneficiaire } from './ResourcesService';
import { plafondAbondementUnilateral } from '../modules/simulateur/fonctions';
import { getCurrentPassFromStore } from '../utils/passUtils';

export const obtenirTexteInfo = (etape, tunnel) => {
  switch (etape) {
    case URLS_ASSURANCE.SIRET:
    case URLS_EPARGNE.SIRET:
      return (
        <div className="info-dossier">
          <Typography variant="h1" color={COLOR.PRIMARY} sx={{
            marginBottom: '0.166rem',
            marginTop: '0.166rem',
            lineHeight: '1.54',
          }}>
            <Trans i18nKey="siret.info.title" />
          </Typography>
          <Typography info>
            <Trans i18nKey="siret.info.part.1" />
          </Typography>
          {isSiretInfoPartie2Enabled() && (
            <div>
              <br />
              <p className="yes-green">
                <Trans i18nKey="siret.info.part.2" />
              </p>
            </div>
          )}
        </div>
      );

    case URLS_EPARGNE.ENTREPRISE_INFORMATION:
    case URLS_ASSURANCE.ENTREPRISE_INFORMATION:
      return (
        isInfoCardEntreprisePart1Enabled() && (
          <div className="info-dossier">
            <Typography variant="h1" color={COLOR.PRIMARY} sx={{
              marginBottom: '0.166rem',
              marginTop: '0.166rem',
              lineHeight: '1.54',
            }}>
              <Trans i18nKey="entreprise.info.title" />
            </Typography>
            <Typography info>
              <Trans i18nKey="entreprise.info.part.1" />
            </Typography>
          </div>
        )
      );

    case URLS_EPARGNE.ENTREPRISE_COMPLEMENT:
      return (
        <div className="info-dossier">
          <span className="titre-info">Infos</span>
          <p className="yes-green">
            <TransHtmlText i18nKey="entreprise.complement.info" />
          </p>
        </div>
      );

    case URLS_ASSURANCE.PPE:
      const tradKey = 'ppe.info';
      return(
        <div className="info-dossier">
          <Typography variant="h1" color={COLOR.PRIMARY} sx={{
            marginBottom: '0.166rem',
            marginTop: '0.166rem',
            lineHeight: '1.54',
          }}>
            <Trans i18nKey={`${tradKey}.titre`}/>
          </Typography>
          <Typography info sx={{marginBottom: "12px"}}>
            <Trans i18nKey={`${tradKey}.main`}/>
          </Typography>
          <Typography info sx={{marginBottom: "12px"}}>
            <Trans i18nKey={`${tradKey}.liste.titre`} />
            <TooltipInfo placement="bottom-start"
              tooltipContent={
                <ul>
                  <Typography info>
                    <li>
                      <Trans i18nKey={`${tradKey}.liste.fonction1`} />
                    </li>
                    <li>
                      <Trans i18nKey={`${tradKey}.liste.fonction2`} />
                    </li>
                    <li>
                      <Trans i18nKey={`${tradKey}.liste.fonction3`} />
                    </li>
                    <li>
                      <Trans i18nKey={`${tradKey}.liste.fonction4`} />
                    </li>
                    <li>
                      <Trans i18nKey={`${tradKey}.liste.fonction5`} />
                    </li>
                    <li>
                      <Trans i18nKey={`${tradKey}.liste.fonction6`} />
                    </li>
                    <li>
                      <Trans i18nKey={`${tradKey}.liste.fonction7`} />
                    </li>
                    <li>
                      <Trans i18nKey={`${tradKey}.liste.fonction8`} />
                    </li>
                    <li>
                      <Trans i18nKey={`${tradKey}.liste.fonction9`} />
                    </li>
                  </Typography>
                </ul>
            } />
          </Typography>
          <Typography info>
            <Trans i18nKey={`${tradKey}.listeProches.titre`} />
            <TooltipInfo placement="bottom-start"
              tooltipContent={
              <>
                <Typography info>
                  <Trans i18nKey={`${tradKey}.listeProches.proche1`} />
                </Typography>
                <ul>
                  <Typography info>
                    <li>
                      <Trans i18nKey={`${tradKey}.listeProches.proche1.sub1`} />
                    </li>
                    <li>
                      <Trans i18nKey={`${tradKey}.listeProches.proche1.sub2`} />
                    </li>
                    <li>
                      <Trans i18nKey={`${tradKey}.listeProches.proche1.sub3`} />
                    </li>
                  </Typography>
                </ul>
              </>
            } />
          </Typography>
        </div>
      );

    case URLS_ASSURANCE.IDENTITE:
    case URLS_EPARGNE.IDENTITE:
      return (
        isDirigeantInfoEnabled() && (
          <div className="info-dossier">
            <Typography
              variant="h1"
              color={COLOR.PRIMARY} sx={{
              marginBottom: '0.166rem',
              marginTop: '0.166rem',
              lineHeight: '1.54',
            }}>
              <TransHtmlText i18nKey="dirigeant.info.title" />
            </Typography>

            <Typography info>
              <TransHtmlText i18nKey="dirigeant.info.part.1" />
            </Typography>
          </div>
        )
      );

    case URLS_ASSURANCE.GARANTES_PREVOYANCE:
      return (
        <div className="info-dossier">
          <Typography
            variant="h1"
            color={COLOR.PRIMARY} sx={{
            marginBottom: '0.166rem',
            marginTop: '0.166rem',
            lineHeight: '1.54',
          }}>
            <TransHtmlText i18nKey="garantiesPrevoyance.info.title" />
          </Typography>
          <Typography info>
            <TransHtmlText i18nKey="garantiesPrevoyance.info.1" />
          </Typography>
        </div>
      );

    case URLS_EPARGNE.DISPOSITIF_ACTUEL:
      return (
        <div className="info-dossier">
          <span className="titre-info">Infos</span>
          <p className="yes-green">
            <TransHtmlText i18nKey="transfert.info" />
          </p>
        </div>
      );

      case URLS_EPARGNE.INTERESSEMENT:
          return (
              <div className="info-dossier">
                  <span className="titre-info">Infos</span>
                  <p className="yes-green">
                      <TransHtmlText
                          i18nKey="interessement.info"
                          params={{
                              config: config
                          }}
                      />
                  </p>
              </div>
          );

      case URLS_EPARGNE.PARTICIPATION:
          if (tunnel.entreprise && tunnel.entreprise.complement && tunnel.entreprise.complement.nombreSalaries) {
              return (
                  <div>
                      {(tunnel.entreprise.complement.nombreSalaries >= 50 || !isParticipationVolontaireEnabled()) && (
                          <div className="info-dossier">
                              <span className="titre-info">Infos</span>
                              <p className="yes-green">
                                  <TransHtmlText
                                      i18nKey="participation.info.plus.49"
                                      params={{
                                          config: config
                                      }}
                                  />
                              </p>
                          </div>
                      )}
                      {tunnel.entreprise.complement.nombreSalaries < 50 && isParticipationVolontaireEnabled() && (
                          <div className="info-dossier">
                              <span className="titre-info">Infos</span>
                              <p className="yes-green">
                                  <TransHtmlText
                                      i18nKey="participation.info.moins.50"
                                      params={{
                                          config: config
                                      }}
                                  />
                              </p>
                          </div>
                      )}
                  </div>
              );
          }
          break;

      case URLS_EPARGNE.DISPOSITIF_ABONDEMENT:
      const PASS = getCurrentPassFromStore();
      return (
        <div className="info-dossier">
          <span className="titre-info">Infos</span>
          <p className="yes-green">
            <TransHtmlText
              i18nKey="dispositif.info.part.1"
              params={{
                config: config,
                plafondPeePass: nombreAvecEspaces(frenchDecimalNumber(PASS * TAUX_MAX_ABONDEMENT_PEE)),
                plafondPercoPass: nombreAvecEspaces(frenchDecimalNumber(PASS * TAUX_MAX_ABONDEMENT_PERCO)),
                plafondAbnUnilateralPass: nombreAvecEspaces(frenchDecimalNumber(plafondAbondementUnilateral(tunnel.dossier))),
                anneeEnCours: new Date().getFullYear()
              }}
            />
          </p>
          <br />
          <p className="yes-green">
            <TransHtmlText i18nKey="dispositif.info.part.2" params={{ config: config }} />
          </p>
        </div>
      );

    case ROUTE_FORMULE_PARTICIPATION:
      return (
        <div className="info-dossier">
          <span className="titre-info">Infos</span>
          <p className="yes-green">
            <Trans i18nKey="participation.formule.info" />
          </p>
        </div>
      );

    case URLS_ASSURANCE.TARIFICATION:
      return (
        <div className="info-dossier">
          <Typography
            variant="h1"
            color={COLOR.PRIMARY} sx={{
            marginBottom: '0.166rem',
            marginTop: '0.166rem',
            lineHeight: '1.54',
          }}>
            <TransHtmlText i18nKey="fraisContrat.infoChoix.titre" />
          </Typography>
          <Typography info>
            <TransHtmlText i18nKey="fraisContrat.infoChoix.1" />
          </Typography>
          <br />
          <Typography
            variant="h1"
            color={COLOR.PRIMARY} sx={{
            marginBottom: '0.166rem',
            marginTop: '0.166rem',
            lineHeight: '1.54',
          }}>
            Infos
          </Typography>
          <Typography info>
            <TransHtmlText i18nKey="fraisContrat.info.1" />
            <br />
            <TransHtmlText i18nKey="fraisContrat.info.2" />
          </ Typography>
          <br />
          <Typography info>
            <TransHtmlText i18nKey="fraisContrat.info.3" />
          </Typography>
          <br />
        </div>
      );

    case URLS_EPARGNE.TARIFICATION:
      return (
        <div className="info-dossier">
          <span className="titre-info">Infos</span>
          <p className="yes-green">
            <TransHtmlText i18nKey="tarification.info" />
          </p>
        </div>
      );

    case URLS_ASSURANCE.KYC:
      return (
        <div className="info-dossier">
          <Typography
            variant={"h1"}
            color={COLOR.PRIMARY}
            sx={{
              marginBottom: '0.166rem',
              marginTop: '0.166rem',
              lineHeight: '1.54',
            }}
          >
            <Trans i18nKey="kyc.page.infos.assurance.title" />
          </Typography>
          <Typography
            info
            sx={{
              marginBottom:'12px'
            }}
          >
            <Trans i18nKey="kyc.page.infos.assurance.subtitle" />
          </Typography>
          {/* Liste des Bénéficiaires Effectifs */}
          <ul>
            {/* Première partie de la liste des Bénéficiaires*/}
            <Typography
              info
              sx={{
                textDecoration:'underline'
              }}
            >
              <li>
                <b>
                  <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.1.title" />
                </b>
                <TooltipInfo
                  maxWidth={MAXWIDTH.LARGE}
                  tooltipContent={
                    <>
                      <Typography info>
                        <b>
                          <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.1.tooltip.title" />
                        </b>
                      </Typography>
                      <ul>
                        <Typography info>
                          <li>
                            <b>
                              <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.1.tooltip.1.title" />
                            </b>
                          </li>
                        </Typography>
                        <Typography info>
                          <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.1.tooltip.1.sub" />
                        </Typography>
                        <Typography info>
                          <li>
                            <b>
                              <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.1.tooltip.2.title" />
                            </b>
                          </li>
                        </Typography>
                        <Typography info >
                          <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.1.tooltip.2.sub" />
                        </Typography>
                      </ul>
                    </>
                  }
                />
              </li>
            </Typography>
            <Typography info>
              <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.1.subtitle" />
            </Typography>
            <ul>
              <Typography
                info
                sx={{
                  marginBottom:'12px'
                }}
              >
                <li>
                  <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.1.1" />
                </li>
                <li>
                  <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.1.2" />
                </li>
              </Typography>
            </ul>
            {/* Deuxième partie de la liste des Bénéficiaires*/}
            <Typography
              info
              sx={{
                textDecoration:'underline'
              }}
            >
              <li>
                <b>
                  <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.2.title" />
                </b>
                <TooltipInfo
                  maxWidth={MAXWIDTH.LARGE}
                  tooltipContent={
                    <>
                      <Typography info>
                        <b>
                          <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.2.tooltip.title" />
                        </b>
                      </Typography>
                      <ul>
                        <Typography info>
                          <li>
                            <b>
                              <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.2.tooltip.1.title" />
                            </b>
                          </li>
                        </Typography>
                        <Typography info>
                          <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.2.tooltip.1.sub" />
                        </Typography>
                        <Typography info>
                          <li>
                            <b>
                              <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.2.tooltip.2.title" />
                            </b>
                          </li>
                        </Typography>
                        <ul>
                          <Typography info >
                            <li>
                              <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.2.tooltip.2.sub.1" />
                            </li>
                            <li>
                              <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.2.tooltip.2.sub.2" />
                            </li>
                          </Typography>
                        </ul>
                      </ul>
                    </>
                  }
                />
              </li>
            </Typography>
            <Typography info>
              <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.2.subtitle" />
            </Typography>
            <ul>
              <Typography
                info
                sx={{
                  marginBottom:'12px'
                }}
              >
                <li>
                  <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.2.1" />
                </li>
                <li>
                  <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.2.2" />
                </li><li>
                <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.2.3" />
              </li>
              </Typography>
            </ul>
            {/* Troisième partie de la liste des Bénéficiaires*/}
            <Typography
              info
              sx={{
                textDecoration:'underline'
              }}
            >
              <li>
                <b>
                  <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.3.title"/>
                </b>
              </li>
            </Typography>
            <Typography info>
              <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.3.subtitle" />
            </Typography>
            <ul>
              <Typography info>
                <li>
                  <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.3.1" />
                </li>
                <li>
                  <Trans i18nKey="kyc.page.infos.assurance.beneficiaireList.3.2" />
                </li>
              </Typography>
            </ul>
          </ul>
        </div>
      );

    case URLS_EPARGNE.KYC:
      return(
        <div className="info-dossier">
          <Typography
            variant={"h1"}
            color={COLOR.PRIMARY}
            sx={{
              marginBottom: '0.166rem',
              marginTop: '0.166rem',
              lineHeight: '1.54',
            }}
          >
            <Trans i18nKey="kyc.page.infos.epargne.title" />
          </Typography>
          <ul>
            <Typography info sx={{
              marginBottom:'12px'
            }}>
              <li>
                <TransHtmlText i18nKey="kyc.page.infos.epargne.mentions"/>
              </li>
            </Typography>
            <Typography info sx={{
              marginBottom:'12px'
            }}>
              <li>
                <TransHtmlText i18nKey="kyc.page.infos.epargne.kbis"/>
              </li>
            </Typography>
            <Typography info>
              <li>
                <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions"/>
              </li>
            </Typography>
            <ul>
              <Typography info>
                <li>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.parts"/>
                </li>
                <li>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.B"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.B.sub.1"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.B.sub.2"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.B.sub.3"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.B.sub.4"/>
                </li>
                <li>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.P"/>
                </li>
                <li>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.A"/>
                </li>
                <li>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.D"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.D.sub.1"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.D.sub.2"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.D.sub.3"/>
                  <TransHtmlText i18nKey="kyc.page.infos.epargne.definitions.D.sub.4"/>
                </li>
              </Typography>
            </ul>
          </ul>
        </div>
      );

    case URLS_ASSURANCE.FINALISATION:
      return (
        <div className="info-dossier">
          <label className="titreFormulaire">
            <Trans i18nKey="assurance.informations.sidemenu.kbis.title" />
          </label>
          <p className="margeInfo">
            <TransHtmlText i18nKey="assurance.informations.sidemenu.kbis.content" />
          </p>
          <label className="titreFormulaire">
            <Trans i18nKey="assurance.informations.sidemenu.enterpriseState.title" />
          </label>
          <p className="margeInfo">
            <TransHtmlText i18nKey="assurance.informations.sidemenu.enterpriseState.content" />
          </p>
          <label className="titreFormulaire">
            <Trans i18nKey="assurance.informations.sidemenu.taxReturn.title" />
          </label>
          <p className="margeInfo">
            <TransHtmlText i18nKey="assurance.informations.sidemenu.taxReturn.content" />
          </p>
          <label className="titreFormulaire">
            <Trans i18nKey="assurance.informations.sidemenu.directorIdentity.title" />
          </label>
          <p className="margeInfo">
            <TransHtmlText i18nKey="assurance.informations.sidemenu.directorIdentity.content" />
          </p>
          <label className="titreFormulaire">
            <Trans i18nKey="assurance.informations.sidemenu.beneficiaryIdentity.title" />
          </label>
          <p className="margeInfo">
            <TransHtmlText i18nKey="assurance.informations.sidemenu.beneficiaryIdentity.content" />
          </p>
          <label className="titreFormulaire">
            <Trans i18nKey="assurance.informations.sidemenu.personneMorale.title" />
          </label>
          <p className="margeInfo">
            <TransHtmlText i18nKey="assurance.informations.sidemenu.personneMorale.content" />
          </p>
        </div>
      );

    case URLS_EPARGNE.FINALISATION:
      return (
        <div className="info-dossier">
          <label className="titreFormulaire">
            <Trans i18nKey="epargne_salariale.informations.sidemenu.kbis.title" />
          </label>
          <p className="margeInfo">
            <TransHtmlText i18nKey="epargne_salariale.informations.sidemenu.kbis.content" />
          </p>
          <p className="margeInfo">
            <a className="infoLink" href="https://www.infogreffe.fr/documents-officiels/extrait-kbis"
               target="_blank" rel="noopener noreferrer">
              <TransHtmlText i18nKey="epargne_salariale.informations.sidemenu.kbis.infogreffe" />
            </a>
          </p>
          <label className="titreFormulaire">
            <Trans i18nKey="epargne_salariale.informations.sidemenu.identity.title" />
          </label>
          <p className="margeInfo">
            <TransHtmlText i18nKey="epargne_salariale.informations.sidemenu.identity.content" />
          </p>
          <p className="margeInfo">
            <a role="button" className="infoLink" onClick={() => getListeBeneficiaire()}>
              <TransHtmlText i18nKey="epargne_salariale.informations.sidemenu.beneficiaryList.link" />
            </a>
          </p>
        </div>
      );

    default:
      break;
  }
};
