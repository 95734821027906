/* built-in imports */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

/* third-party imports */
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

/* Internal imports */
import API from '../../../../../utils/constantes/API';
import Button from '../../../../DS/inputs/Button';
import CircularProgress from '../../../../DS/feedback/Progress';
import DIVIDER from '../../../../../utils/constantes/DS/DIVIDER';
import Divider from '../../../../DS/data-display/Divider';
import File from '../../../../DS/inputs/File/File';
import Typography from '../../../../DS/data-display/Typography';
import componentUtils from '../../../../../utils/US/components/epargne_salariale/forms/finalisationAttachmentsFormUtils';
import styles from './finalisationAttachmentsFormStyles';
import { ROUTE_DOSSIERS_GALAXY, TYPE_DOCUMENT } from '../../../../../common/Configuration';
import { TransHtmlText } from '../../../../yes/utils/TransHtmlText';
import {
  setInformationsDossier,
  setInformationsEntreprise,
  setInformationsUtilisateur,
  setLoader,
  setLoaderMessage
} from '../../../../../modules/tunnel/redux/tunnelActions';

const EpargneInformationsAttachmentsForm = (
  {
    documents,
    enterpriseId,
    entrepriseLegalStatus,
    folderId,
    getSpecificFilesData,
    justificatifIdentite,
    justificatifsCNIAnnexeKYC,
    justificatifsKBisAnnexeKYC,
    listeBeneficiaires,
    participationAgreement,
    profitSharingAgreement,
    PPVAgreement,
    rib,
    setInformationsDossier,
    setInformationsEntreprise,
    setInformationsUtilisateur,
    setLoader,
    setLoaderMessage,
    tradKey,
    userId
  }) => {
  const submitButtonRef = useRef(null),
    { t } = useTranslation(),
    [files, setFiles] = useState({}),
    [isSubmitDisabled, setIsSubmitDisabled] = useState(true),
    [submitData, setSubmitData] = useState({ isError: false, errorMessage: null }),
    [isLoading, setIsLoading] = useState(false),
    classes = styles();

  const getCommonFilesData = () => {
    const filesData = {};
    const allDocuments = Array.prototype.concat(documents.required, documents.optional);
    for(let i = 0, documentsLength = allDocuments.length; i < documentsLength; i++) {
      const document = allDocuments[i];
      let fileData;
      switch (document.type) {
        case TYPE_DOCUMENT.ANNEXE_KYC_PERSON: {
          fileData = componentUtils.getMultipleFilesData(justificatifsCNIAnnexeKYC);
          break;
        }
        case TYPE_DOCUMENT.ANNEXE_KYC_ENTITY: {
          fileData = componentUtils.getMultipleFilesData(justificatifsKBisAnnexeKYC);
          break;
        }
        case TYPE_DOCUMENT.RIB:
          fileData = componentUtils.getFile(rib);
          break;
        case TYPE_DOCUMENT.ACCORD_INTERESSEMENT:
          fileData = componentUtils.getFile(profitSharingAgreement);
          break;
        case TYPE_DOCUMENT.ACCORD_PARTICIPATION:
          fileData = componentUtils.getFile(participationAgreement);
          break;
        case TYPE_DOCUMENT.ACCORD_PPV:
          fileData = componentUtils.getFile(PPVAgreement);
          break;
        case TYPE_DOCUMENT.STATUTS_ENTREPRISE:
          fileData = componentUtils.getFile(entrepriseLegalStatus);
          break;
        case TYPE_DOCUMENT.LISTE_BENEFICIAIRES:
          fileData = componentUtils.getFile(listeBeneficiaires);
          break;
        case TYPE_DOCUMENT.JUSTIFICATIF_IDENTITE:
          fileData = componentUtils.getDirectorIdentityFiles(justificatifIdentite);
          break;
        default:
          continue;
      }
      filesData[document.key] = fileData;
    }
    return filesData;
  }

  const onAddMultipleFiles = (key) => {
    const currentFiles = files[key];
    currentFiles.push({
      file: null,
      networkError: false,
      networkErrorMessage: null,
      isLoading: false
    });

    setFiles({
      ...files,
      [key]: currentFiles
    });
  };

  const onRemoveMultipleFiles = (key, index) => {
    const newFiles = files[key];
    newFiles.splice(index, 1);
    setFiles({
      ...files,
      [key]: newFiles
    });
  };

  const updateFiles = (key, index) => {
    const currentFiles = files[key];
    currentFiles[index].file = null;
    setFiles({
      ...files,
      [key]: currentFiles
    });
  };

  const onAddFile = (key, index, file) => {
    const newFiles = files[key];
    newFiles[index].file = file;
    setFiles({
      ...files,
      [key]: newFiles
    });
  };

  const uploadFile = async (key, fileUrl, thenMethod, index, file) => {
    let response = null;

    setIsLoading(true);
    componentUtils.setCurrentFile(files, setFiles, index, key, true, false, null);

    try {
      response = await componentUtils.uploadFile(fileUrl, file);
    } catch(error) {
      componentUtils.setCurrentFile(files, setFiles, index, key, false, true, error.response?.data?.message ?? error.message);
      setIsLoading(false);
      return;
    }

    onAddFile(key, index, file);
    thenMethod(response.data);
    componentUtils.setCurrentFile(files, setFiles, index, key, false, false, null);
    setIsLoading(false);
  };

  const removeFile = async (key, fileUrl, thenMethod, index, fileName, thenFileMethod) => {
    let response = null;

    setIsLoading(true);
    componentUtils.setCurrentFile(files, setFiles, index, key, true, false, null);

    try {
      response = await componentUtils.deleteFile(fileUrl, fileName);
    } catch(error) {
      componentUtils.setCurrentFile(files, setFiles, index, key, false, true, error.response?.data?.message ?? error.message);
      setIsLoading(false);
      return;
    }

    thenMethod(response.data);
    componentUtils.setCurrentFile(files, setFiles, index, key, false, false, null);
    thenFileMethod(key, index);
    setIsLoading(false);
  };

  const disabledManager = () => {
    let isDisabled = true;
    if(!componentUtils.checkMissingRequiredDocuments(documents, files)) {
      isDisabled = false;
    }
    setIsSubmitDisabled(isDisabled);
  };

  const activeAppLoader = (msg) => {
    setLoaderMessage(msg);
    setLoader(true);
  };

  const disableAppLoader = () => {
    setLoaderMessage(null);
    setLoader(false);
  };

  const sendFolder = async () => {
    let response = null;

    setSubmitData({
      ...submitData,
      isLoading: true
    });
    activeAppLoader(t("loader.transmission_message"));

    try{
      response = await componentUtils.sendFolder(`${API.FOLDERS.SEND.replace(API.PARAMS.FOLDERID, folderId).replace(API.PARAMS.FOLDERTYPE, '')}`);
    } catch(error) {
      disableAppLoader();
      setSubmitData({
        isLoading: false,
        errorMessage: error.response.data.message
      });
      return;
    }

    setInformationsDossier(response.data);
    setSubmitData({
      ...submitData,
      isLoading: false
    });
    disableAppLoader();
    window.location = process.env.REACT_APP_STATIC_URL + ROUTE_DOSSIERS_GALAXY;
  };

  const isFiles = () => {
    return Object.entries(files).length > 0;
  };

  const getDispatchFunction = (entityType) => {
    switch (entityType) {
      case API.CITRA.USERS:
        return setInformationsUtilisateur;
      case API.CITRA.ENTERPRISE:
        return setInformationsEntreprise;
      case API.CITRA.FOLDERS:
        return setInformationsDossier;
      default:
        return;
    }
  };

  const getUrl = (entityType, baseUrl) => {
    switch (entityType) {
      case API.CITRA.USERS:
        return baseUrl.replace(API.PARAMS.USERID, userId);
      case API.CITRA.ENTERPRISE:
        return baseUrl.replace(API.PARAMS.ENTERPRISEID, enterpriseId);
      case API.CITRA.FOLDERS:
        return baseUrl.replace(API.PARAMS.FOLDERID, folderId);
      default:
        return;
    }
  };

  useEffect(() => {
    const commonFiles = getCommonFilesData();
    const specificFiles = getSpecificFilesData();

    setFiles(Object.assign(commonFiles, specificFiles));
  }, []);

  useEffect(() => {
    if (!isFiles() || isLoading) { return; }
    disabledManager();
  }, [files, isLoading]);

  useEffect(() => {
    if(!isSubmitDisabled){
      submitButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isSubmitDisabled]);

  return (
    <>
      <Grid item xs={12}>
        <Divider color={DIVIDER.COLOR.PRIMARY}>{t(`${tradKey}.divider`)}</Divider>
      </Grid>
      <style jsx>
        {`
          .label-bold-secondary {
              animation: blinker 0.7s infinite;
          }

          @keyframes blinker {
              0% { opacity: 1; }
              50% { opacity: 0.2; }
              100% { opacity: 1; }
          }
        `}
      </style>
      {isFiles() && documents?.required && documents?.optional && (
        Array.prototype.concat(documents.required, documents.optional).map(
          (document, index) => (
            <Grid key={document?.key ?? index} item xs={12} lg={(document.canBeJustOne || document.canAddFiles) ? 12 : 6}>
              {document.key && files[document.key] && (
                <File
                  files={files[document.key]}
                  filetypes={document.fileExtension}
                  justOne={document.canBeJustOne}
                  label={<TransHtmlText i18nKey={(`${tradKey}.${document.tradKey}`)} />}
                  onFileAdd={document.canAddFiles ? (() => onAddMultipleFiles(document.key)) : null}
                  optional={document.canAddFiles || documents.optional.includes(document)}
                  required={documents.required.includes(document)}
                  tooltipContent={document.tooltipTradKey ? t(`${tradKey}.${document.tooltipTradKey}`) : null}

                  onChange={async (index, file) => uploadFile(
                    document.key,
                    getUrl(document.entite, document.baseUrl),
                    getDispatchFunction(document.entite),
                    index,
                    file
                  )}

                  onRemove={ async (index, file) => {
                    if (file) {
                      await removeFile(
                        document.key,
                        getUrl(document.entite, document.baseUrl),
                        getDispatchFunction(document.entite),
                        index,
                        file.name,
                        updateFiles
                      );
                      return;
                    }
                    if(document.canAddFiles) {
                      onRemoveMultipleFiles(document.key, index);
                    }
                  }}
                />
              )}
            </Grid>
          )
        )
      )}
      {submitData.errorMessage && (
        <Grid item xs={12}>
          <Typography
            warning
            indicator
          >
            {submitData.errorMessage}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          ref={submitButtonRef}
          className={(!isSubmitDisabled && !submitData.isLoading) ? classes.elementFlash : ''}
          disabled={isSubmitDisabled || submitData.isLoading}
          onClick={sendFolder}
          variant="contained">
          {!submitData.isLoading ? t(`${tradKey}.buttons.validate`) :  <CircularProgress className='margin_O' />}
        </Button>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  enterpriseId: state.tunnel.entreprise.uid,
  entrepriseLegalStatus: state.tunnel.entreprise.statuts,
  folderId: state.tunnel.dossier.uid,
  justificatifIdentite: state.tunnel.utilisateur.justificatifIdentite,
  justificatifsCNIAnnexeKYC: state.tunnel.dossier.justificatifsCNIAnnexeKYC,
  justificatifsKBisAnnexeKYC: state.tunnel.dossier.justificatifsKBisAnnexeKYC,
  listeBeneficiaires: state.tunnel.dossier.listeBeneficiaires,
  participationAgreement: state.tunnel.dossier.documentAccordParticipation,
  profitSharingAgreement: state.tunnel.dossier.documentAccordInteressement,
  PPVAgreement: state.tunnel.dossier.documentAccordPPV,
  rib: state.tunnel.dossier.rib,
  userId: state.tunnel.utilisateur.uid
});

const mapDispatchToProps = (dispatch) => ({
  setInformationsDossier: (dossier) => dispatch(setInformationsDossier(dossier)),
  setInformationsEntreprise: (entreprise) => dispatch(setInformationsEntreprise(entreprise)),
  setInformationsUtilisateur: (utilisateur) => dispatch(setInformationsUtilisateur(utilisateur)),
  setLoader: (loader) => dispatch(setLoader(loader)),
  setLoaderMessage: (loaderMessage) => dispatch(setLoaderMessage(loaderMessage))
});

export default connect(mapStateToProps, mapDispatchToProps)(EpargneInformationsAttachmentsForm);
