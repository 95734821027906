// Actions déclenchées par les actioncreators publiques
export const TUNNEL_AT = {
  UPDATE_SIRENE_INFO: 'tunnel/UPDATE_SIRENE_INFO',
  RESET_SIRENE_INFO: 'tunnel/RESET_SIRENE_INFO',
  RESET_ALL_STATE: 'tunnel/RESET_ALL_STATE',
  UPDATE_RETOUR_ROUTE: 'tunnel/UPDATE_RETOUR_ROUTE',
  UPDATE_ENTREPRISE_INFO: 'tunnel/UPDATE_ENTREPRISE_INFO',
  UPDATE_DOSSIER_INFO: 'tunnel/UPDATE_DOSSIER_INFO',
  UPDATE_REDIRECT_ETAPE: 'tunnel/UPDATE_REDIRECT_ETAPE',
  UPDATE_UTILISATEUR_INFO: 'tunnel/UPDATE_UTILISATEUR_INFO',
  UPDATE_DONNEES_PPE: 'tunnel/UPDATE_DONNEES_PPE',
  UPDATE_INFOS_COMPL_ENTREPRISE: 'tunnel/UPDATE_INFOS_COMPL_ENTREPRISE',
  UPDATE_MODALITES_PERO: 'tunnel/UPDATE_MODALITES_PERO',
  UPDATE_GARANTIES_PREVOYANCE: 'tunnel/UPDATE_GARANTIES_PREVOYANCE',
  UPDATE_FRAIS_CONTRAT: 'tunnel/UPDATE_FRAIS_CONTRAT',
  UPDATE_PLAN_EXISTANT: 'tunnel/UPDATE_PLAN_EXISTANT',
  UPDATE_INTERESSEMENT: 'tunnel/UPDATE_INTERESSEMENT',
  UPDATE_PARTICIPATION: 'tunnel/UPDATE_PARTICIPATION',
  UPDATE_PARTICIPATION_VOLONTAIRE: 'tunnel/UPDATE_PARTICIPATION_VOLONTAIRE',
  UPDATE_DATES_PARTICIPATION_VOLONTAIRE: 'tunnel/UPDATE_DATES_PARTICIPATION_VOLONTAIRE',
  UPDATE_ABONDEMENTS: 'tunnel/UPDATE_ABONDEMENTS',
  UPDATE_ABONDEMENT_UNILATERAL: 'tunnel/UPDATE_ABONDEMENT_UNILATERAL',
  UPDATE_TARIFICATION: 'tunnel/UPDATE_TARIFICATION',
  UPDATE_GES_TARIFICATION: 'tunnel/UPDATE_GES_TARIFICATION',
  UPDATE_INFORMATIONS: 'tunnel/UPDATE_INFORMATIONS',
  UPDATE_LIASSE: 'tunnel/UPDATE_LIASSE',
  REMOVE_LIASSE: 'tunnel/REMOVE_LIASSE',
  UPDATE_SERVEUR_ERROR_FORM: 'tunnel/UPDATE_SERVEUR_ERROR_FORM',
  UPDATE_LOADER: 'tunnel/UPDATE_LOADER',
  UPDATE_LOADER_MESSAGE: 'tunnel/UPDATE_LOADER_MESSAGE',
  UPDATE_EMAIL_DEJA_EXISTANT: 'tunnel/UPDATE_EMAIL_DEJA_EXISTANT',
  UPDATE_DONNEES_DIRIGEANT: 'tunnel/UPDATE_DONNEES_DIRIGEANT',
  SET_NOUVELLE_ENTREPRISE: 'tunnel/SET_NOUVELLE_ENTREPRISE',
  UPDATE_FICHE_PERSONNE_MORALE: 'tunnel/UPDATE_FICHE_PERSONNE_MORALE',
  UPDATE_MOYENS_PAIEMENT: 'tunnel/UPDATE_MOYENS_PAIEMENT',
  UPDATE_FICHES_PERO: 'tunnel/UPDATE_FICHES_PERO',
  UPDATE_CATEGORIES_SALARIES: 'tunnel/UPDATE_CATEGORIES_SALARIES'
};

export const setInformationsSirene = (sirene) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_SIRENE_INFO,
    sirene
  });
};

export const resetState = () => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.RESET_ALL_STATE
  });
};

export const setRetourRoute = (routeRetour) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_RETOUR_ROUTE,
    routeRetour
  });
};

export const setServerErrorForm = (errorForm) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_SERVEUR_ERROR_FORM,
    errorForm
  });
};

export const setInformationsEntreprise = (entreprise) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_ENTREPRISE_INFO,
    entreprise
  });
};

export const setInformationsComplementairesEntreprise = (complement) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_INFOS_COMPL_ENTREPRISE,
    complement
  });
};

export const setModalitesPero = (modalitesPero) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_MODALITES_PERO,
    modalitesPero
  });
};

export const setGarantiesPrevoyance = (garantiesPrevoyance) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_GARANTIES_PREVOYANCE,
    garantiesPrevoyance
  });
};

export const setFraisContrat = (fraisContrat) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_FRAIS_CONTRAT,
    fraisContrat
  });
};

export const setPlanExistant = (planExistant) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_PLAN_EXISTANT,
    planExistant
  });
};

export const setCompteDePrelevement = (compteDePrelevement) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_TARIFICATION,
    compteDePrelevement
  });
};

export const setTarification = (tarification) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_GES_TARIFICATION,
    tarification
  });
};
export const setInteressement = (interessement) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_INTERESSEMENT,
    interessement
  });
};

export const setParticipation = (participation) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_PARTICIPATION,
    participation
  });
};

export const setParticipationVolontaire = (participationVolontaire) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_PARTICIPATION_VOLONTAIRE,
    participationVolontaire
  });
};

export const setAbondements = (dispositifs) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_ABONDEMENTS,
    dispositifs
  });
};

export const setAbondementUnilateral = (abondementUnilateral) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_ABONDEMENT_UNILATERAL,
    abondementUnilateral
  });
};

export const setInformationsDossier = (dossier) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_DOSSIER_INFO,
    dossier
  });
};

export const setFichePersonneMorale = (fiche) => (dispatch) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_FICHE_PERSONNE_MORALE,
    fiche
  });
};

export const enableRedirectRouteEtape = (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_REDIRECT_ETAPE,
    redirectEtape: true
  });
};

export const toggleRedirectRouteEtape = (redirectEtape) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_REDIRECT_ETAPE,
    redirectEtape
  });
};

export const setInformationsUtilisateur = (utilisateur) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_UTILISATEUR_INFO,
    utilisateur
  });
};

export const setDonneesDirigeant = (donneesDirigeant) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_DONNEES_DIRIGEANT,
    donneesDirigeant
  });
};

export const setDonneesPpe = ({ ppe, prochePpe }) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_DONNEES_PPE,
    fichePersonneMorale: {
      ppe,
      prochePpe
    }
  });
};

export const setInformations = (information) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_INFORMATIONS,
    information
  });
};

export const setLiasse = (liasse) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_LIASSE,
    liasse
  });
};

export const removeLiasse = () => (dispatch) => {
  dispatch({
    type: TUNNEL_AT.REMOVE_LIASSE
  });
};

export const setLoader = (loader) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_LOADER,
    loader
  });
};

export const setLoaderMessage = (loaderMessage) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_LOADER_MESSAGE,
    loaderMessage
  });
};

export const setDatesParticipationVolontaire = (dates) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_DATES_PARTICIPATION_VOLONTAIRE,
    dates
  });
};

export const setEmailDejaExistant = (emailDejaExistant) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_EMAIL_DEJA_EXISTANT,
    emailDejaExistant
  });
};

export const setMoyensPaiement = (moyensPaiement) => (dispatch, getState) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_MOYENS_PAIEMENT,
    moyensPaiement
  });
};

export const setFichesPero = (fichesPero) => (dispatch) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_FICHES_PERO,
    fichesPero
  });
}

export const setCategoriesSalaries = (categoriesSalaries) => (dispatch) => {
  dispatch({
    type: TUNNEL_AT.UPDATE_CATEGORIES_SALARIES,
    categoriesSalaries
  })
}

export const setNouvelleEntreprise = ({ entreprise, dossier, utilisateur }) => (dispatch, getState) => {
  dispatch(setInformationsEntreprise(entreprise));
  dispatch(setInformationsDossier(dossier));
  dispatch(setInformationsUtilisateur(utilisateur));
};
