/* built-in imports */
import React, { useEffect, useState } from 'react';

/* third-party imports */
import { Field, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

/* internal imports */
import { BOOLEAN } from '../../../../common/constants';
import { YesButton } from '../../../form/Button';
import { YesChoix } from '../../../form/Choix';
import { booleanToString } from '../../../../common/FunctionsUtils';

const DispositifActuelForm = ({ handleSubmit, pristine, invalid, submitting, tunnel, change }) => {
  const [hasPlan, setHasPlan] = useState(tunnel.dossier.planExistant && tunnel.dossier.planExistant.hasPlanExistant);

  const resetTransfertPlan = () => (change('transfertPlan', BOOLEAN.NO));

  useEffect(() => {
    if (tunnel.dossier.planExistant !== null) {
      change('hasPlanExistant', booleanToString(tunnel.dossier.planExistant.hasPlanExistant));
      change('transfertPlan', tunnel.dossier.planExistant.transfertPlan ? BOOLEAN.YES : BOOLEAN.NO);
      change('accordInteressementEnPlace', tunnel.dossier.planExistant.accordInteressementEnPlace ? BOOLEAN.YES : BOOLEAN.NO);
      change('accordParticipationEnPlace', tunnel.dossier.planExistant.accordParticipationEnPlace ? BOOLEAN.YES : BOOLEAN.NO);
      change('accordPPVEnPlace', tunnel.dossier.planExistant.accordPPVEnPlace ? BOOLEAN.YES : BOOLEAN.NO);
    }
  }, [tunnel.dossier.planExistant]);

  return (
    <form onSubmit={handleSubmit} className="formTunnel formDispositifActuel">
      <label className="titreFormulaire">
        <Trans i18nKey="transfert.titre" />
      </label>

      <div className="grid-x grid-margin-x">
        <div className="cell medium-12">
          <h3>
            <Trans i18nKey="transfert.question.dejaUnPlan" />
          </h3>

          <div className="tunnelRadios">
            <div className="tunnelRadio">
              <Field
                onChange={() => setHasPlan(true)}
                name="hasPlanExistant"
                component={YesChoix}
                type="radio"
                value="oui"
                label="Oui"
                id="hasPlanExistant-oui"
              />
            </div>

            <div className="tunnelRadio">
              <Field
                onChange={() => {
                  setHasPlan(false);
                  resetTransfertPlan();
                }}
                name="hasPlanExistant"
                component={YesChoix}
                type="radio"
                value="non"
                label="Non"
                id="hasPlanExistant-non"
              />
            </div>
          </div>
        </div>
      </div>
      {hasPlan && (
        <>
          <div className="grid-x grid-margin-x">
            <div className="cell medium-12">
              <h3>
                <Trans i18nKey="transfert.question.transferePlan" />
              </h3>

              <div className="tunnelRadios">
                <div className="tunnelRadio">
                  <Field name="transfertPlan" component={YesChoix} type="radio" value="oui" label="Oui" id="transfertPlan-oui" />
                </div>

                <div className="tunnelRadio">
                  <Field name="transfertPlan" component={YesChoix} type="radio" value="non" label="Non" id="transfertPlan-non" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="grid-x grid-margin-x">
        <div className="cell medium-12">
          <h3>
            <Trans i18nKey="transfert.question.accordInteressementEnPlace" />
          </h3>

          <div className="tunnelRadios">
            <div className="tunnelRadio">
              <Field name="accordInteressementEnPlace" component={YesChoix} type="radio" value="oui" label="Oui" id="accordInteressementEnPlace-oui" />
            </div>

            <div className="tunnelRadio">
              <Field name="accordInteressementEnPlace" component={YesChoix} type="radio" value="non" label="Non" id="accordInteressementEnPlace-non" />
            </div>
          </div>
        </div>
      </div>
      <div className="grid-x grid-margin-x">
        <div className="cell medium-12">
          <h3>
            <Trans i18nKey="transfert.question.accordParticipationEnPlace" />
          </h3>

          <div className="tunnelRadios">
            <div className="tunnelRadio">
              <Field name="accordParticipationEnPlace" component={YesChoix} type="radio" value="oui" label="Oui" id="accordParticipationEnPlace-oui" />
            </div>

            <div className="tunnelRadio">
              <Field name="accordParticipationEnPlace" component={YesChoix} type="radio" value="non" label="Non" id="accordParticipationEnPlace-non" />
            </div>
          </div>
        </div>
      </div>
      <div className="grid-x grid-margin-x">
        <div className="cell medium-12">
          <h3>
            <Trans i18nKey="transfert.question.accordPPVEnPlace" />
          </h3>

          <div className="tunnelRadios">
            <div className="tunnelRadio">
              <Field name="accordPPVEnPlace" component={YesChoix} type="radio" value="oui" label="Oui" id="accordPPVEnPlace-oui" />
            </div>

            <div className="tunnelRadio">
              <Field name="accordPPVEnPlace" component={YesChoix} type="radio" value="non" label="Non" id="accordPPVEnPlace-non" />
            </div>
          </div>
        </div>
      </div>

      <div className="buttonCreationContainer">
        <YesButton text="Confirmer" disabled={invalid || submitting || pristine} />
      </div>
    </form>
  );
};

// REDUX + REDUX FORM
const mapStateToProps = (state) =>({
  tunnel: state.tunnel
});

export default reduxForm({ form: 'dispositifActuelForm' })(connect(mapStateToProps)(DispositifActuelForm));
